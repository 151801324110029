@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?846r9d');
  src:  url('./fonts/icomoon.eot?846r9d#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?846r9d') format('truetype'),
    url('./fonts/icomoon.woff?846r9d') format('woff'),
    url('./fonts/icomoon.svg?846r9d#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e900";
}
.icon-office:before {
  content: "\e903";
}
.icon-book:before {
  content: "\e91f";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-arrow-up-right:before {
  content: "\ea33";
}
