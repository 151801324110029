
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Noto+Sans+JP:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=KoHo&display=swap');



.div-cont{
    width: 90%;
    display: flex;
}

.cap-info >section{
    display: flex;

}


.div-cont > div{
    padding-top: 8rem;
    padding-bottom: 8rem;
    align-items: center;
    display: flex;
    justify-content: center;

}

.media-container-div{
    width: 60%;

}

.car-container-div{
    width: 40%;
}


.div-cont >div >div{
    width: 80%;

}

.div-cont > div iframe{
    width: 80%;
    height: 387px;

}

.aux-intr-sec{
    background-color: #fffaf5;
}

.aux-hab-sec{
    background-color:white;
}



.cap-int-card >div{
    width: 70%;
}



.div-cont img{
    width: 90%
}



.cap-int-card-title{
    font-size:4rem;
    margin-bottom: 4rem;
    color: #f0932b;
    font-family: 'Noto Sans JP', sans-serif;
}

.subtitle{
    font-size:2rem;
    margin-bottom: 3rem;
    color: black;
    font-family: 'KoHo', sans-serif;

}

.cap-int-card-text{
    font-size:1.1rem;

}

.hab-div-cont{
    padding-left:.8rem;
    border-left: solid 4px #f0932b;

}

.niña-img{
    width: 50%!important;
}

.aux-guia-sec{
    background-color: #f5f5f5;
}