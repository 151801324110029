@media screen and (max-width: 770px)
{



        .diseno-intr-sec{
            background: #EECDA3;  /* fallback for old browsers */
            background: -webkit-linear-gradient(to right, #EF629F, #EECDA3);  /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to right, #EF629F, #EECDA3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            
        }
}