@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.convocatoria-ingreso{
    display: flex;
    position:absolute;
    align-items: center;
    justify-content:space-between;
    align-items: baseline;
    height: 100%;
    z-index: 1;

}

.convocatoria-ingreso-card {
    text-overflow: ellipsis;
    
}



.convocatoria-ingreso{
    width: 80%;
    margin-left: 13%;
    margin-top:5%;
}


@media screen and (max-width : 770px)
{
    .noticeBoard_section{
        padding: 0px !important;;
    }

    .convocatoria-ingreso-card h2{
    font-size: 1rem;
        
    }

    .convocatoria-ingreso{
        width: 80%;
        margin-left: 5%;
        margin-top:5%;
    }

    .convocatoria-ingreso-card p{
        font-size: 0.6rem;
            
        }

    .convocatoria-ingreso-card a{
        font-size: 0.8rem;
            
        }


}

.banner_buttons_continer{
    display: flex;

}

.banner_buttons_continer a{
    margin-right: 1rem;
}

.convocatoria-ingreso-card h2{
    color: #fbc531;
    margin-bottom: 2%;
    font-family: 'Roboto', sans-serif;
    
}

.convocatoria-ingreso-card p{

    color: rgb(255, 255, 255);

}

.carousel-indicators li {

    background-color: #0000ff;
}

.carousel-indicators .active {

    background-color: #e7760d;
}

.carousel-control-prev span{
    background-color: black;
}

.carousel-control-next span{
    background-color: black;
}

.car-img{
    filter: brightness(30%);
    z-index: 0;
}


.lista-convo{
    border-left:3px solid #fbc531;
    margin-top:0.2rem!important;
    margin-bottom:0.2rem!important;
    padding-left:10px!important;
    list-style: none;
}

