@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Noto+Sans+JP:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Noto+Sans+JP:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=KoHo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Newsreader:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Reggae+One&display=swap);
.logoCeb {
    float: left;
    width: 40%;

}

.navbar{
    margin:auto;
    width: 85%;
}

header{
    box-shadow: 0px 3px 8px 0px rgba(80, 80, 80, 0.75);
    background: #f8f9fa;
    border-bottom: 1px solid  #e0e0e0;
    margin-bottom: 50px;
}




.navbar-nav >div >a,
.navbar-nav  >a{

    padding:10px 20px!important;
    font-family: 'Montserrat', sans-serif!important;

}

.dropdown-item a{
    color: black!important;
}

.icon-home{
    margin-left: 7px;

}

.transporte-tables{
    background-color: white;
}

.dropdown-item >a,
.dropdown-menu >.dropdown {
    width: 100%;
    padding-left: 1.25rem;
    padding-top: .7rem;
    padding-bottom:.7rem;
    display: block;
    padding-right: 1.25rem;
}

.dropdown-item {
    padding:0!important;
}



@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.7fcaef3d.eot);
  src:  url(/static/media/icomoon.7fcaef3d.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.b3e9a2d4.ttf) format('truetype'),
    url(/static/media/icomoon.2c36a594.woff) format('woff'),
    url(/static/media/icomoon.a860afb9.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e900";
}
.icon-office:before {
  content: "\e903";
}
.icon-book:before {
  content: "\e91f";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-arrow-up-right:before {
  content: "\ea33";
}

.convocatoria-ingreso{
    display: -webkit-flex;
    display: flex;
    position:absolute;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    height: 100%;
    z-index: 1;

}

.convocatoria-ingreso-card {
    text-overflow: ellipsis;
    
}



.convocatoria-ingreso{
    width: 80%;
    margin-left: 13%;
    margin-top:5%;
}


@media screen and (max-width : 770px)
{
    .noticeBoard_section{
        padding: 0px !important;;
    }

    .convocatoria-ingreso-card h2{
    font-size: 1rem;
        
    }

    .convocatoria-ingreso{
        width: 80%;
        margin-left: 5%;
        margin-top:5%;
    }

    .convocatoria-ingreso-card p{
        font-size: 0.6rem;
            
        }

    .convocatoria-ingreso-card a{
        font-size: 0.8rem;
            
        }


}

.banner_buttons_continer{
    display: -webkit-flex;
    display: flex;

}

.banner_buttons_continer a{
    margin-right: 1rem;
}

.convocatoria-ingreso-card h2{
    color: #fbc531;
    margin-bottom: 2%;
    font-family: 'Roboto', sans-serif;
    
}

.convocatoria-ingreso-card p{

    color: rgb(255, 255, 255);

}

.carousel-indicators li {

    background-color: #0000ff;
}

.carousel-indicators .active {

    background-color: #e7760d;
}

.carousel-control-prev span{
    background-color: black;
}

.carousel-control-next span{
    background-color: black;
}

.car-img{
    -webkit-filter: brightness(30%);
            filter: brightness(30%);
    z-index: 0;
}


.lista-convo{
    border-left:3px solid #fbc531;
    margin-top:0.2rem!important;
    margin-bottom:0.2rem!important;
    padding-left:10px!important;
    list-style: none;
}




/*cuerpo=====================================================*/

.anu form{
    padding:15px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 5px 10px;
}

.anu form input{
    margin: 5px 0;
}

.generic-anu{
    font-size:14;
    color:#454444;
    background:#eef5ff;
    border-radius: 3px;
    overflow:hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 15px;

}

.p {
    text-align: center;
    color:white;
    background:#2C4C66;
    border-radius: 2px 2px 0px 0px;
    padding: 5px;
    font-size: 17;
}	

/*boton calif=====================================================*/

.p-btn{
    z-index:1;
    background:#336699;
    padding:5px 10px;
    border-radius:5px;
    box-shadow: 0px 2px 0 #09337b;
    border:none;
    color:white;
    transition:all 0.3s;
    text-align:center;
    font-family:arial;
    position:relative;
    top:0;
    display: inline-block;
}

.p-btn:hover{
   box-shadow:0px 2px 0 #9d5004;
   background:#EC6D14;}

.p-btn:active{
   box-shadow:none;
   top:2px;}



 .presentacion-section{
    display: -webkit-flex;
    display: flex;
    background-color: white;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

 }

 .presentacion-section >div{
    margin-bottom:30px ;

 }

 .cuerpo{
   width: 100%;
}

 .widgets{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 25%;
 }

 .static-Info{
    width: 73%;
    margin-right: 2%;

 }

 .titulo-presentacion{
    margin-bottom: 40px;
 }

 .imgInicio{
    
    margin-top:30px;
 }

 .cosulta-calif input{
    border:1px solid #5e92f3;

 }

 .cosulta-calif p{
    margin-bottom: 0;
    margin-top:1rem;

 }
@media screen and (max-width : 770px)
{
    .widgets, .static-Info{

        width: 100%;
        
     }
    

}

.titular{

  background-color: hsl(337, 100%, 97%);
  text-align: center;
  margin-top: 30px;
}

.listaaa{
  list-style: inside;

}

.quienes-somos-section h1{
    font-size: 90;
    font-family: 'Lato', sans-serif;
    color: #474747;
    padding-bottom:30px;
}

.quienes-somos-section{
    padding:30px 10%;
    background-color: white;
    padding-right: 25%;
}
.galeria .carousel-item >div{
  width: 100%;
  height: 600px;
  background-color: #454444;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  
  }  

.gallery-section{
  padding-top:5px;
  padding-bottom: 30px;

}




.mision-vision{
    padding: 30px 5%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.mision-vision h2{
    margin-bottom: 30px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.mision-vision div{
    padding: 40px 20px;
    width: 45%;
    border-radius: 1px;
}



.quienes{
    margin-top:30px;
    background-color: #3949ab;
    padding:30px 10%;
    color: white;
}

.quienes h1{
    margin-bottom: 30px;
}
@media screen and (max-width: 770px)
{
    
    .mision-vision div{
        width: 100%!important;
    }

    .mision-vision {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    

}
footer{
    margin-top: 50px;
    border-top: 1px solid #22262b;
    padding: 60px 5%;
    background: #444c57;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.links{
    padding-top: 20px;
    border-top: 1px solid #b2becf;
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    
}

.links li{
    list-style: none;
    color:  #848e9b!important;

}

.links ol{
    margin-left: -40px;

}

.links div{
    margin-right: 40px;
    
}

.links p{
    color: #b2becf ;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
}


.links a:hover{
    color:  #789fcf!important;
}

.ubi{
    background:#2a2f36;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding:10px;
    color:#bdc6d3;
}

.ubi p{
    padding-right: 20px;
}

.social-media{
    color: #b2becf ;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;

}

.social-media img{
    width: 40px;

}

@media screen and (max-width : 770px)
{
    .links >div{

        width: 100%;
        
     }
    
     footer>div{
         width: 100%!important;
     }


}



.transporte-tables table{
    BACKGROUND:#E6E6E6;
    list-style:none;
    border-collapse:collapse;
    color:#454444;
    text-align: center;
    border-radius: 3px;
    
    margin-bottom: 30px;
}

.transporte-tables table li{
    text-align: left;
    font-weight: 200;
    list-style: none;
    margin-left: -40px;
}

.transporte-tables table th{
    padding: 5px 10px;
}

.transporte-tables table th{
    border: 1px solid rgb(204, 204, 204);

}

.transporte-tables{
    margin: auto;

}

.topu{
    color:white;
    background:#1D75CD;
}

.kk{
    background:#2C4C66;
    color:white;
}

.b{
    BACKGROUND:WHITE;
}

.ubicacion-section{
    background-color: white;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:space-between;
            justify-content:space-between;

}

.google-maps{
    width: 65%;
    
}

@media screen and (max-width: 770px)
{
    
    .ubicacion-section{
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .google-maps{
        width: 95%;
        margin-bottom:1rem ;
        
    }


}



.div-cont{
    width: 90%;
    display: -webkit-flex;
    display: flex;
}

.cap-info >section{
    display: -webkit-flex;
    display: flex;

}


.div-cont > div{
    padding-top: 8rem;
    padding-bottom: 8rem;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;

}

.media-container-div{
    width: 60%;

}

.car-container-div{
    width: 40%;
}


.div-cont >div >div{
    width: 80%;

}

.div-cont > div iframe{
    width: 80%;
    height: 387px;

}

.aux-intr-sec{
    background-color: #fffaf5;
}

.aux-hab-sec{
    background-color:white;
}



.cap-int-card >div{
    width: 70%;
}



.div-cont img{
    width: 90%
}



.cap-int-card-title{
    font-size:4rem;
    margin-bottom: 4rem;
    color: #f0932b;
    font-family: 'Noto Sans JP', sans-serif;
}

.subtitle{
    font-size:2rem;
    margin-bottom: 3rem;
    color: black;
    font-family: 'KoHo', sans-serif;

}

.cap-int-card-text{
    font-size:1.1rem;

}

.hab-div-cont{
    padding-left:.8rem;
    border-left: solid 4px #f0932b;

}

.niña-img{
    width: 50%!important;
}

.aux-guia-sec{
    background-color: #f5f5f5;
}
@media screen and (max-width: 770px)
{
    
    .div-cont > div{
        width: 100%;
        
    }

    .div-cont{
        -webkit-flex-direction: column;
                flex-direction: column;
    }

}

.div-cont-inf {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    z-index: 1;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    position: absolute;

}

.cap-info >section{
    display: -webkit-flex;
    display: flex;

}



.imagen-fondo-intr{
    width: 100%;
}


.div-cont-inf > div{
    padding-top: 8rem;
    padding-bottom: 8rem;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;

}

.media-container-div-info{
    width: 55%;

}

.car-container-div-info{
    width: 45%;
}


.div-cont-inf >div >div{
    width: 80%;

}

.div-cont-inf > div iframe{
    width: 80%;
    height: 387px;

}

.cap-int-card >div{
    width: 70%;
}


.cap-int-card-title-info{
    font-size:4.5rem;
    margin-bottom: 3rem;
    color: #feca57;
    font-family: 'Bebas Neue', cursive;
}




.subtitle{
    font-size:2rem;
    margin-bottom: 3rem;
    color: black;
    font-family: 'KoHo', sans-serif;

}

.subtitle-habs{
font-size:3rem;
margin-bottom: 8rem;
margin-top: 4rem;
width: 70%;
}

.general-info{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.cap-int-card-text-info{
    font-size:1.3rem;
    color: white;

}

.imagen-hojas{
    width: 90%;

}

.cap-int-card-text-info-gral{

    color:black;
    font-size:1.1rem;
}

.habs{
    background-color: white;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.habs-cards-cont{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap

}
.habs h2{
    text-align: center;
    font-family: 'Prompt', sans-serif;
    margin-left: 2rem;
    margin-right: 2rem;
}

.title-marked-hab{
    color: #4834d4;
}

.hab-card{
    width: 20%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;

}

.hab-card img{
    width: 90%;
}

.hab-card h5{
    font-size: 1.2rem;
    text-align: center;
    font-family: 'KoHo', sans-serif;
    font-weight: bold;

}

.hab-card p {

    text-align: center;
    font-size: 0.8rem;
    color: rgb(57, 57, 57);

    
    
}




@media screen and (max-width: 770px)
{
    
    .hab-card{
        margin-bottom: 2rem;
    }


    .car-container-div-info,
    .media-container-div-info,
    .hab-card,
    .div-cont-inf > div iframe
    {
        width: 100%;
    }

    .imagen-fondo-intr{
        display: none;
    }

    .subtitle-habs{

        width: 100%;
        }



        .inf-intr-sec{
            background-color: #7237e6;
        }
        .div-cont-inf{
            position: static;
        }
}

.intr-salud-sec{
    -webkit-justify-content: left;
            justify-content: left;

}

.imagen-fondo-intr-salud{
    width: 100%;

}

.intr-card-salud{
    width: 30%;
    z-index: 1;
    position: absolute;
    margin-left: 12rem;


}

.intr-salud-sec{

    background-color: white
}

.intr-card-salud-title{
    

    font-size: 3rem;
    color: black;
    margin-bottom: 2rem!important;
    font-family: 'Merriweather', serif;

}

.res-title-intr-salud{

    margin-top: 1rem;
    color: #e74c3c;
    font-family: 'Merriweather', serif;

}

.intr-card-salud-text{
    font-size: 1.3rem;
}



.intr-card-salud-text{
    color: rgb(71, 71, 71);
}

.video-salud{
    width: 80%;

}

.video-salud-section{
    background-color: rgb(251, 247, 247);
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.div-sec{
    padding-top: 12rem;
    padding-bottom: 12rem;
    background-color: white;
}

.subT-card{
    font-family: 'KoHo', sans-serif;
    font-size: 3rem;
    margin-bottom: 1rem;


}

.text-card{
    font-size: 1.2rem;
    color: rgb(64, 64, 64);
}

.div-panels-cont{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.div-panels-cont > div{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.media-div-cont{
    width: 55%;
}

.card-div-cont{
    width: 45%;

}

.cap-card{
    width: 80%;
}

.img-cap-div{
    width: 50%;

}
.sub-card-salud{
    font-family: 'Newsreader', serif;
    color: #953b31;
}

.epidem-sec{
    background-color: #f7f1e5;
}

.nutri-sec{
    background-color: #ffffff;
}

.public-sec{
    background-color: #eeeff4;

}

.mat-cap-salud{
    background-color: #f7f1e5;
    padding-top: 10rem;
    padding-bottom: 10rem;

}

.disc-salud-cont{
    width: 70%;

}

.disc-salud-tit{
    font-size: 4rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;

}

.disc-salud-tit-res{
    color: #953b31;
}
@media screen and (max-width: 770px){
    
    .imagen-fondo-intr-salud{
        display: none;
    }

    .div-panels-cont > div{
        width: 100%;
    }

    .intr-card-salud{
        width: 90%;
        margin-left: 0;
        position:static;
    }

    .intr-salud-sec{
        padding-top: 3rem;
        padding-bottom: 3rem;
        background-color: white
    }

    .intr-salud-sec{
        -webkit-justify-content: center;
                justify-content: center;
    
    }


    .img-cap-div{
        width: 95%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    
    }

    .disc-salud-cont{
        width: 100%;
        
    }

    .disc-salud-tit{
        font-size: 3rem!important;
    }


}




.intr-sec-cont{
    padding-top: 9rem;
    padding-bottom: 9rem;

    background: #007991;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #78ffd6, #007991); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */






}



.video-conta{
    width:80%;

}


.intr-card-cont-title{

    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #262e0e;
     
}

.intr-card-cont-text{
    font-size: 1.3rem;
    color: white;
}


.res-title-intr-cont{
    font-size: 6rem;
    color: #A3CB38;
    font-family: 'Oswald', sans-serif;   
}


.sub-card-cont{
    margin-bottom: 2rem;

    color:teal;

}

.admon-sec{
    background-color: white;
}

.materias-sec-cont{
    padding-top: 10rem;
    padding-bottom: 10rem ;
    background-color: rgb(247, 247, 247);
}

.materias-cont-cont{
    width: 70%;
}

.mater-cont-tit-pres{
    text-align: center;
    color: #262e0e;
    font-size: 4rem;
}

.mater-cont-tit-pres b{
    color: teal!important;
}
@media screen and (max-width: 770px)
{
    

    .materias-cont-cont{
        width: 90%;
    }
    
.mater-cont-tit-pres{
    text-align: center;
    color: #262e0e;
    font-size: 3rem;
    
}

.res-title-intr-cont{
    font-size: 2.5rem;
}

.intr-card-cont-title{

    font-size: 2rem;


    
}

.cap-card{
    margin-top: 4rem;
}


}
.cap-int-card-title-diseno{
    font-size: 3rem;
    font-family: 'Josefin Sans', sans-serif; 
    color:#22167d;

   
}
.cap-int-card-text-diseno{

    font-size: 1.3rem;
    color: #2c3e50;
    font-family: 'Josefin Sans', sans-serif; 

    

}


.cap-int-card-title-diseno-rem{
    font-size:6rem;
    font-family: 'Reggae One', cursive;
    color:#3522c4;


}

.diseno-int-sec{
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

@media screen and (max-width: 770px)
{



        .diseno-intr-sec{
            background: #EECDA3;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to right, #EF629F, #EECDA3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            
        }
}
@media screen and (max-width: 770px)
{
    
    html{
        font-size:4vw;
    }

    .visible-for-desk{
        display: none;
    }

}
@media screen and (min-width: 770px)
{
    
    html{
        font-size:1vw;
    }

    .visible-for-mov{
        display: none;
    }
}

body{
  background-color:#eef5ff;
}

section {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.gen-sec-grid{
    padding: 30px 10%;

}

