@media screen and (max-width: 770px)
{
    
    html{
        font-size:4vw;
    }

    .visible-for-desk{
        display: none;
    }

}