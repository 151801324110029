@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap');
.cap-int-card-title-diseno{
    font-size: 3rem;
    font-family: 'Josefin Sans', sans-serif; 
    color:#22167d;

   
}


@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
.cap-int-card-text-diseno{

    font-size: 1.3rem;
    color: #2c3e50;
    font-family: 'Josefin Sans', sans-serif; 

    

}

@import url('https://fonts.googleapis.com/css2?family=Reggae+One&display=swap');


.cap-int-card-title-diseno-rem{
    font-size:6rem;
    font-family: 'Reggae One', cursive;
    color:#3522c4;


}

.diseno-int-sec{
    flex-wrap: wrap;
}
