@media screen and (min-width: 770px)
{
    
    html{
        font-size:1vw;
    }

    .visible-for-mov{
        display: none;
    }
}

body{
  background-color:#eef5ff;
}

section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gen-sec-grid{
    padding: 30px 10%;

}
