
.ubicacion-section{
    background-color: white;
    display:flex;
    justify-content:space-between;

}

.google-maps{
    width: 65%;
    
}
